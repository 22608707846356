/* eslint-disable react/no-unknown-property */
/** @jsx jsx */
import { Flex, Box } from '@rebass/grid/emotion';
import { Container } from '../components/Container/';
import { Fixture } from '../components/Fixture';
import { Grid } from '../components/Grid';
import { GridCell } from '../components/GridCell';
import { Layout } from '../components/Layout';
import { jsx } from '../jsx';

const GridApi = () => (
  <Layout>
    <Container>
      <Flex flexWrap="wrap">
        <Box bg="light-pink" width={[1, 1 / 2, 1 / 3]}>
          <Fixture>1</Fixture>
        </Box>
        <Box bg="light-green" width={[1, 1 / 2, 1 / 3]}>
          <Fixture>2</Fixture>
        </Box>
        <Box bg="light-blue" width={[1, 1 / 2, 1 / 3]}>
          <Fixture>3</Fixture>
        </Box>
      </Flex>

      <div bg="moon-gray" mb={3}>
        <Grid>
          <GridCell>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell>
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell>
            <Fixture>3</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid>
          <GridCell width={1 / 4}>
            <Fixture minHeight={100}>1</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture minHeight={100}>3</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>4</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>5</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture minHeight={100}>6</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>7</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture minHeight={100}>8</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid>
          <GridCell width={1 / 4}>
            <Fixture>1/4</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>auto</Fixture>
          </GridCell>
        </Grid>
        <Grid>
          <GridCell width={2 / 12}>
            <Fixture>2/12</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>auto</Fixture>
          </GridCell>
          <GridCell width={2 / 12}>
            <Fixture>2/12</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid justifyContent="center">
          <GridCell width={1 / 3}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell width={1 / 3}>
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell width={1 / 2}>
            <Fixture>3</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid justifyContent="flex-end">
          <GridCell width={1 / 4}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>3</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid alignItems="center">
          <GridCell width={1 / 4}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture minHeight={100}>2</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>3</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>4</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid alignItems="flex-end">
          <GridCell width={1 / 4}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture minHeight={100}>2</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>3</Fixture>
          </GridCell>
          <GridCell width={1 / 4}>
            <Fixture>4</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid>
          <GridCell flex="1 1 0%">
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>3</Fixture>
          </GridCell>
        </Grid>
        <Grid>
          <GridCell flex="1 1 0%">
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>3</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>4</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>5</Fixture>
          </GridCell>
          <GridCell flex="1 1 0%">
            <Fixture>6</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid>
          <GridCell display="flex" width={1 / 3}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell display="flex" width={1 / 3}>
            <Fixture minHeight={110}>2</Fixture>
          </GridCell>
          <GridCell display="flex" width={1 / 3}>
            <Fixture>3</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid>
          <GridCell width={1 / 2}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell width={1 / 2}>
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell mx="auto" width={3 / 4}>
            <Fixture>3: centered cell</Fixture>
          </GridCell>
        </Grid>
      </div>

      <div bg="moon-gray" mb={3}>
        <Grid mx={-2}>
          <GridCell width={1 / 4} px={2}>
            <Fixture>1</Fixture>
          </GridCell>
          <GridCell width={1 / 4} px={2}>
            <Fixture>2</Fixture>
          </GridCell>
          <GridCell width={1 / 4} px={2}>
            <Fixture>3</Fixture>
          </GridCell>
          <GridCell width={1 / 4} px={2}>
            <Fixture>4</Fixture>
          </GridCell>
        </Grid>
      </div>
    </Container>
  </Layout>
);

export default GridApi;
