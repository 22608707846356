/* eslint-disable react/no-unknown-property */
/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '../jsx';

export const Fixture = ({ children, ...props }) => (
  <div
    bg="rgb(100, 150, 50)"
    mb={2}
    minHeight={50}
    borderWidth={1}
    borderStyle="solid"
    borderColor="black"
    {...props}
  >
    {children}
  </div>
);

Fixture.propTypes = {
  children: PropTypes.node,
};
